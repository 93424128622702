<template>
  <div class="container" style="min-height: 47vh;">
    <div class="row">
      <div
        class="col-md-12"
        style="display: flex; flex-direction: column; justify-content: center; align-items: center;"
      >
        <img
          :src="this.$store.state.colourLogo"
          style="width: 10%; border-radius: 10px; margin-bottom: 10px;"
        />
        <!-- <p class="simpal-text-underLogo text-center">SIMPLY, SUPREME<p> -->
        <p style="padding: 0% 7%;" class="simpal-text text-center">
          We believe Cochchi.lk to be the next big thing in Sri Lanka’s e-commerce market. Having started as a simple idea to support local sellers with a platform to broaden
          their scope, today our vision for Cochchi.lk has evolved in its goal to also be that
          of a digital lifestyle partner to all its customers. Featuring an array of items, across
          myriad registered merchants, Cochchi.lk is the place to be when it comes to
          online sales. Cochchi.lk is guided by SLTMobitel the digital technology leader in Sri
          Lanka, making every service, one that comes with decades of trusted experience
          and reliability.
        </p>
      </div>
    </div>
    <!-- <div class="row main-rows">
            <div class="col-md-6 left-text">
                <P class="sub-heading">YOU STAY – WE DELIVER</P>
                <p class="simpal-text">We are capable and committed to deliver your orders 
                    right to your door in a safe and timely manner. We take greater caution 
                    on the careful packaging and all aspects of hygiene and disease prevention 
                    guided by our team of experts with years of expertise in global ecommerce.
                </p>
                <button class="Browse-btn" @click="browseClick">Browse Now</button>
            </div>
            <div class="col-md-6" style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
                <img src="/assets/image/product/delivery_man@2x.png" style="width: 60%;">
            </div>
        </div>
        <div class="row main-rows" style="flex-wrap: wrap-reverse;">
            <div class="col-md-6"  style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
                <img src="/assets/image/product/girl_with_tv@2x.png" style="width: 60%; width: 60%;">
            </div>
            <div class="col-md-6 right-text">
                <p class="sub-heading">THE ULTIMATE HOME DELIVERY OPTION</p>
                <p class="simpal-text">We enter the market at a time, when your stay and comfort 
                    at home is of paramount importance. Your safety and health related concerns 
                    are our priority when planning the deliveries and packaging. Our dedicated customer 
                    service personnel, supported by the professional delivery team will ensure all your 
                    delivery needs are met in the most satisfactory manner at all times. 
                </p>
            </div>
        </div>
        <div class="row main-rows">
            <div class="col-md-6 left-text">
                <p class="sub-heading">THE END GAME – AN UNSTARVED NATION / AFFORDABLE PRICING.</p>
                <p class="simpal-text"> Affordable Pricing. Unique/Innovative delivery methods. Dedicated
                    customer service. The strength to retain stocks at all times. The willingness to stay ahead of the rest whilst being cheaper and of higher quality.
                </p>
                <p class="simpal-text">Support us,</p>
                <p class="simpal-text">Together - Let’s Make a Difference.</p>
            </div>
            <div class="col-md-6" style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
                <img src="/assets/image/product/handover_pack@2x.png" style="width: 60%;">
            </div>
    </div>-->
  </div>
</template>
<script>
export default {
  metaInfo: {
    title: "About Us",
    titleTemplate: "%s | cochchi.lk | Online Shopping Sri Lanka",
    meta: [
      { charset: "utf-8" },
      {
        name: "description",
        content:
          "cochchi.lk online shopping site in Sri Lanka is available for your Daily Essential Needs. Online Shopping Sri Lanka.Home Delivery (Within Delivery Grid)."
      },
      { name: "viewport", content: "width=device-width, initial-scale=1" }
    ],
    htmlAttrs: {
      lang: "en",
      amp: true
    }
  },
  methods: {
    browseClick() {
      this.$router.push("/").catch(()=>{});
    }
  }
};
</script>
<style scoped>
.sub-heading {
  font-size: 28px;
  font-weight: bold;
  line-height: 1.35;
  color: black;
  margin-bottom: 10px;
}
.simpal-text {
  font-size: 18px;
  font-weight: 300;
  line-height: 27px;
  line-height: 1.35;
  color: black;
}

.simpal-text-underLogo {
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  line-height: 1.35;
  color: black;
}
.left-text {
  padding-left: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.right-text {
  padding-left: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  display: -webkit-box;
}

.main-rows {
  margin-top: 5%;
  display: flex;
  flex-wrap: wrap;
  display: -webkit-box;
  -webkit-flex-wrap: wrap;
}
.Browse-btn {
  color: #000;
  font-size: 16px;
  text-align: center;
  background-color: #ffd300;
  max-width: 50%;
  padding: 2%;
  border: none;
}

.Browse-btn:hover,
.Browse-btn:focus {
  /* outline: none; */
  color: #ffffff;
}
</style>